.unslider{overflow:auto;margin:0;padding:0}.unslider-wrap{position:relative}.unslider-wrap.unslider-carousel>li{float:left}.unslider-vertical>ul{height:100%}.unslider-vertical li{float:none;width:100%}.unslider-fade{position:relative}.unslider-fade .unslider-wrap li{position:absolute;left:0;top:0;right:0;z-index:8}.unslider-fade .unslider-wrap li.unslider-active{z-index:10}.unslider li,.unslider ol,.unslider ul{list-style:none;margin:0;padding:0;border:none}.unslider-arrow{position:absolute;left:20px;z-index:2;cursor:pointer}.unslider-arrow.next{left:auto;right:20px}
.unslider-nav ol {
  list-style: none;
  text-align: center;
  margin-top: 2rem;
}
.unslider-nav ol li {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 .8rem;
  background: transparent;
  border-radius: 50%;
  overflow: hidden;
  text-indent: -999em;
  cursor: pointer;
  background-color: #075ab7; 
}
.unslider-nav ol li.unslider-active {
  background: #fff;
  cursor: default;
}

.unslider-arrow {
  display: none;
}